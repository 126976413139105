      import React, { useState, useEffect } from 'react';
      import './Pentesting.css';

      import coverImage1 from './image1.jpg'; // Le chemin doit être relatif au dossier src
      import coverImage2 from './image2.jpg';
      import coverImage3 from './image3.jpg';
      import coverImage4 from './image4.jpg';

      const coverImages = [
        coverImage1,
        coverImage2,
        coverImage3,
        coverImage4,
        // ... plus d'images si nécessaire
      ];

      const HeroSection = () => {
        const [currentImageIndex, setCurrentImageIndex] = useState(0);

        useEffect(() => {
          const intervalId = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % coverImages.length);
          }, 10000); // Change image every 15 seconds

          return () => clearInterval(intervalId);
        }, [currentImageIndex]);

        return (
          <div
            className="hero-section"
            style={{ backgroundImage: `url(${coverImages[currentImageIndex]})` }}>
            <div className="hero-overlay">
              <h1 className="hero-title">Professionelles Pentesting</h1>
              <p className="sous-titre">
                Entdecken Sie Schwachstellen, bevor sie ausgenutzt werden.
              </p>
            </div>
          </div>
        );
      };

      const Counter = ({ target, duration }) => {
        const [count, setCount] = useState(0);

        useEffect(() => {
          let frame;
          const startTimestamp = performance.now();

          const step = (timestamp) => {
            if (!frame) frame = requestAnimationFrame(step);
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            setCount(Math.floor(progress * (target - 0) + 0));
            if (progress < 1) {
              frame = requestAnimationFrame(step);
            }
          };

          requestAnimationFrame(step);

          return () => cancelAnimationFrame(frame);
        }, [target, duration]);

        return <div className="circle">{count}</div>;
      };


      // Pentesting component
      const Pentesting = () => {

        return (

          <div>

      <HeroSection /> 


          <div className="Pentesting-page">
            


            {/* Section 2 */}
            
            <div className=" sectiona1">
                <img src="/image001.webp" alt="Visual representation" className="section1-img" />
                <div className="sectiona1-text">
                    <h2>ISO 27001 Penetration Testing als Schlüssel zur Informationssicherheit</h2>
                    ISO 27001 Penetrationstests sind ein entscheidender Bestandteil im Prozess der Informationssicherheit. Diese Form des Security Assessments zielt darauf ab, die Sicherheit von Systemen, Netzwerken und ganzen Organisationen zu evaluieren. Dabei simulieren Experten Cyberattacken, um Schwachstellen und potentielle Abweichungen von der ISO 27001 Compliance aufzudecken. Dieses Vorgehen hilft Organisationen, ihre Sicherheitslage zu verstehen und Bereiche der Nichtkonformität sowie mögliche Schwachstellen zu identifizieren. </div>
            </div>


            {/* Section 3 */}
            <div className="white-sectiona1">
              <div className="boxa1">
                <h4 className="titlea1">Neuerungen im TISAX-Standard und ihre Auswirkungen</h4>
                <p className="texta1">TISAX, der Sicherheitsstandard der OEM seit 2014, hat in der Version VDA ISA 6.0, erschienen im Oktober 2023, bedeutende Änderungen erfahren. Neben neuen Prüfzielen wie Vertraulichkeit und Verfügbarkeit liegt ein verstärkter Fokus auf Cybersecurity. Diese Änderungen führen zu neuen Prüfungsschwerpunkten und Kontrollen, die während der Sicherheitsbewertung berücksichtigt werden müssen..</p>
              </div>
              <div className="boxa2">
                <h4 className="titlea1">Methoden und Techniken für effektive Sicherheitsbewertungen</h4>
                <p className="texta1">Penetrationstests umfassen eine Vielzahl von Techniken, darunter semi-automatische Schwachstellenscans, manuelle Verifikation von Sicherheitslücken, Überprüfung von Security-Konfigurationen und Reverse Engineering. Durch ein standardisiertes Vorgehen, ergänzt durch optionale Module, wird eine hohe Qualität in Penetrationstest-Projekten gewährleistet. Dieser Prozess beinhaltet Schritte wie Kick-off-Meetings, intelligente Datensammlung (OSINT) und detaillierte Analysen von Einfallstoren.</p>
              </div>
              <div className="boxa3">
                <h4 className="titlea1">Zielgruppe und Verantwortliche für die Sicherheitsbewertung</h4>
                <p className="texta1">ISO 27001 Penetrationstests sind relevant für Führungskräfte in der IT-Sicherheit (wie CISOs und CIOs), oberes Management, Prüfungsteams, Wirtschaftsprüfer und Cybersicherheitsexperten. Sie sind essentiell für technische Manager und Product Owner, die an Projekten zur ISO 27001-Compliance beteiligt sind. Durch die Tests erhalten diese Stakeholder wichtige Einblicke in die Sicherheitslage und Compliance ihrer Organisationen.</p>
              </div>
            </div>



              {/* section des etapes*/} 
              <div className="penetration-testing-section">
                <h2>Schützen Sie proaktiv Ihre IT-Assets mittels Penetration Testing</h2>
                <p>Beim Penetration Testing kommen beispielsweise folgende Techniken zum Einsatz:</p>
                <ul>
                  <li>Kombination aus semi-automatischen Schwachstellenscans & manuellem Testing</li>
                  <li>Manuelle Verifikation von Sicherheitslücken</li>
                  <li>Review von Security-Konfigurationen, -Designs & Code</li>
                  <li>Identifikation von Fehlkonfigurationen</li>
                  <li>Netzwerk- & Paketanalyse</li>
                  <li>API Monitoring</li>
                  <li>Reverse Engineering</li>
                  <p>Durch bewährtes und standardisiertes Vorgehen und mit weiteren optionalen Modulen wird eine hohe Qualität in Penetrationstest-Projekten sichergestellt:</p>

                  {/* ... autres éléments de la liste ... */}
                </ul>
        
        <div className="process-steps">
          {/* Ici, vous pouvez utiliser une liste ou des divs pour vos étapes, avec des images ou des icônes comme décrit */}
          
          <div class="process-steps-container">
        <div class="process-step">
          <div class="process-icon">1</div>
          <p>Kick-off-meeting</p>
        </div>

        <div class="process-steps-container">
        <div class="process-step">
          <div class="process-icon">2</div>
          <p>intelligente Garthering(OSINT)</p>
        </div>

        <div class="process-steps-container">
        <div class="process-step">
          <div class="process-icon">3</div>
          <p>Status Update</p>
        </div>
        
        <div class="process-connector"></div>
        <div class="process-step">
          <div class="process-icon">4</div>
          <p>Analyse Einfallstor & Ausnutzung</p>
        </div>
        <div class="process-connector"></div>
        <div class="process-step">
          <div class="process-icon">5</div>
          <p> Dokumentation</p>
        </div>
        <div class="process-connector"></div>
        <div class="process-step">
          <div class="process-icon">6</div>
          <p>Schllussbesprechung</p>
        </div>
      </div>

        </div>
      
        
        </div>
      
        </div>
        <p>Nach Abschluss der Testaktivitäten erhalten Sie einen detaillierten, zielgruppengerechten Schlussbericht, welcher unter anderem folgende Informationen enthält:</p>
        <ul>
          <li>Management Summaryg</li>
          <li>Projektziele</li>
          <li>Rahmenbedingungen</li>
          <li>Befunde (Sicherheitslücken inkl. Risikokategorisierung)</li>
          <li>Massnahmenempfehlungen</li>
          <li>Optional: Rohdaten</li>
          
          <p>Bei einer Schlussbesprechung inklusive Präsentation werden die Resultate ausführlich erläutert und detailliert auf die Massnahmenempfehlungen eingegangen</p>

          {/* ... autres éléments de la liste ... */}
        </ul>
        </div>
            
            {/* Statistics section */}
            <div className="statistics-container">
              <h2>Das bestätigen unsere Statistiken</h2>
              <p>Täglich setzen Unternehmen weltweit auf unsere Fachkompetenz. Das bestätigen zusätzlich zu den langjährigen Kunden auch unsere Statistiken.</p>
              <div className="stats">
                <div className="stat-item">
                  <Counter target={220} duration={1000} />
                  <p>Application (Mobile)<br />Penetration Test Projekte</p>
                </div>
                <div className="stat-item">
                  <Counter target={300} duration={1000} />
                  <p>Penetration Test<br />Projekte pro Jahr</p>
                </div>
                <div className="stat-item">
                  <Counter target={80} duration={1000} />
                  <p> (ISO 27001 )<br />Penetration Test Projekte</p>
                </div>
              </div>
            </div>

            <div className="sectiona2">
                <div className="sectiona2-text">
                    <h2>Entscheidende Rolle von Penetrationstests im Rahmen von ISO/IEC 27001</h2>
                    ISO/IEC 27001 ist als anerkannter Standard im Bereich der Informationssicherheit unverzichtbar. Er setzt die Messlatte für die Einrichtung, Implementierung und Aufrechterhaltung eines effektiven Informationssicherheitsmanagementsystems (ISMS) in Organisationen. Um die Einhaltung dieser Norm zu gewährleisten, spielen ISO 27001 Penetrationstests eine zentrale Rolle. Sie ermöglichen es, die Sicherheit von Systemen, Anwendungen, Netzwerken und der gesamten organisatorischen Infrastruktur umfassend zu bewerten. In diesen Tests simulieren Sicherheitsexperten Cyberangriffe, um Schwachstellen und potenzielle Sicherheitsrisiken aufzudecken. Diese Bewertungen sind entscheidend, um die Sicherheitslage einer Organisation zu verstehen, Bereiche der Nichtkonformität zu identifizieren und die Auswirkungen möglicher Schwachstellen realistisch einzuschätzen.
                </div>
                <img src="/image012.webp" alt="Visual representation" className="section1-img" />
            </div>



            <div className="sectiona3">
                <img src="/image004.webp" alt="Visual representation" className="section1-img"/>
                <div className="sectiona3-text">
                    <h2>Wichtige Stakeholder und ihre Rolle im Penetration Testing Prozess</h2>
                    ISO 27001 Penetrationstests sind von großer Bedeutung für eine Vielzahl von Fachleuten und Entscheidungsträgern in Unternehmen. Dies umfasst Führungskräfte, die für die IT-Sicherheit verantwortlich sind, wie CISOs, VPs of Security und CIOs, sowie das obere Management, einschließlich CEOs, CTOs, COOs und CFOs. Ebenso sind Mitglieder von Prüfungsteams und -ausschüssen, Wirtschaftsprüfer sowie Compliance-Beauftragte wesentliche Zielgruppen dieses Prozesses. Darüber hinaus profitieren Cybersicherheitsexperten in Bereichen wie Application Security, Security Operations und Infrastructure Security von den Erkenntnissen, die durch Penetrationstests gewonnen werden. Technische Manager und Product Owner, die an ISO 27001-Compliance-Projekten beteiligt sind, erhalten durch diese Tests wertvolle Einblicke in die Sicherheitsstandards und -anforderungen, die für den Schutz ihrer Organisationen entscheidend sind..
                </div>
            </div>
          
          </div>
          </div>
        );
      };
      export default Pentesting;